import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import PairTable from "@components/table"
import EmbedMap from "@components/map"
import * as Button from "@components/button"
import BreadCrumbs from "@components/breadcrumbs";
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";
import { StaticImage } from "gatsby-plugin-image"


const Company = () => (
  <Layout>
    <Meta
      title="2023年 展示会開催のご案内"
      desc="2023年 展示会開催のご案内を掲載しています"
    />{ }

    <Heading.H1 text="展示会開催のご案内" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <BreadCrumbs
        lists={[
          {
            string: "会社情報",
            path: "/company",
          },
          {
            string: "お知らせ",
            path: "/news",
          },
          {
            string: "展示会開催のご案内",
            path: "/exhibition-2023",
          }
        ]}
      />
      <StaticImage src="../images/exhibition-2023.png" className="object-contain mb-8 lg:mt-5" />
    </div>
  </Layout>
)

export default Company;